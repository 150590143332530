<template>
  <b-form-checkbox
    :checked="status === 1 ? true : false"
    :disabled="isSubmitting"
    name="subscribe"
    switch
    inline
    @change="changeContactSubscription(contactId, $event)"
  >
    {{ label }}
  </b-form-checkbox>
</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BFormCheckbox,
  },
  props: ['status', 'contactId', 'label', 'onChange'],
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    async changeContactSubscription(contactId, value) {
      this.isSubmitting = true
      try {
        await AccountService.updateContact(contactId, { subscribed_to_communication: value })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Item updated successfully',
                variant: 'success',
              },
            })

            if (typeof this.onChange === 'function') {
              // convert boolean value to number
              this.onChange(this.contactId, Number(value))
            }
            this.isSubmitting = false
          })
      } catch (error) {
        console.error(error)
        this.isSubmitting = false
      }
    },
  },
}
</script>
