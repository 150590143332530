<template>
  <b-form-select
    :value="status"
    :disabled="isSubmitting"
    @change="changeContactStatus(contactId, $event)"
  >
    <b-form-select-option value="new">
      new
    </b-form-select-option>
    <b-form-select-option value="first_visit">
      first visit
    </b-form-select-option>
    <b-form-select-option value="didnt_attend">
      didn't attend
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BFormSelect,
    BFormSelectOption,
  },
  props: ['status', 'contactId', 'onChange'],
  data() {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    async changeContactStatus(contactId, status) {
      this.isSubmitting = true
      try {
        await AccountService.updateContact(contactId, { status })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Status changes successfully',
                variant: 'success',
              },
            })

            if (typeof this.onChange === 'function') {
              this.onChange(this.contactId, status)
            }
            this.isSubmitting = false
          })
      } catch (error) {
        console.error(error)
        this.isSubmitting = false
      }
    },
  },
}
</script>
