<template>
  <div>
    <ContactStats />
    <ContactList :search-query="this.$route.query.q" />
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ContactList from '@/views/components/contacts/ContactList'
import ContactStats from '@/views/components/contacts/ContactStats'
import store from '@/store'

export default {
  components: {
    ContactStats,
    ContactList,
    BCard,
    BCardText,
    BLink,
    vSelect,
  },
  data() {
    return {
      options: [],
      timezone: 'foo',
      timezoneList: [],
    }
  },
  async created() {
    if (!store.getters['contacts/loading']) {
      store.dispatch('contacts/loadContactList')
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
