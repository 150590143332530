<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      v-if="contacts"
      no-body
    >
      <b-row>
        <b-col>
          <b-input-group size="lg">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="cursor-pointer"
                @click=""
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-container
      v-if="contacts"
      class="filter-container mb-2"
      fluid="sm"
    >
      <b-row
        class="top-row"
        align-v="start"
      >
        <b-col class="top-filter-col">
          <b-button-group
            class="contact-list-filterbuttons"
            :size="screenWidth > 639 ? 'md' : 'sm'"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('id')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'ID')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              ID
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('first_name')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'first_name')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              First Name
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('last_name')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'last_name')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              Last Name
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('visit_date')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'visit_date')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              Visit date
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('location')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'location')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              Location
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="setAndToggleSort('status')"
            >
              <feather-icon
                v-if="checkIfFilterVisible(currentSort, 'status')"
                :icon="changeFilterIcon(currentSortDir)"
                class-name="mr-50"
              />
              Status
            </b-button>
            <!--
            <b-button
              :disabled="!filter"
              variant="outline-primary"
              @click="filter = ''"
            >
              Clear
            </b-button>
            -->
          </b-button-group>
        </b-col>
        <b-col
          class="top-pagination-col"
        >
          <template v-if="total > perPage">
            <b-pagination
              v-model="currentPage"
              class="mb-0"
              :size="screenWidth > 480 ? '' : 'sm'"
              :total-rows="total"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
            />
          </template>
          <div
            class="top-pagination-button"
          >
            <b-button
              to="/contacts/new"
              variant="primary"
            >
              New contact
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      fluid="sm"
      class="filter-container"
    >
      <b-row>
        <b-col v-if="contacts">
          <b-card
            v-for="contact in filteredContacts"
            :key="contact.id"
            class="contact-list-card"
          >
            <b-card-body class="no-padding">
              <table

                class="b-table contact-list-table"
                style="width:100%;"
              >
                <tbody>
                  <tr>
                    <td
                      v-b-toggle="`contact-${contact.id}`"
                      style="width:100px;"
                    >
                      <b-avatar
                        variant="primary"
                        :text="contact.first_name.charAt(0) + contact.last_name.charAt(0)"
                        class="mr-25"
                      />
                    </td>
                    <td
                      v-b-toggle="`contact-${contact.id}`"
                      class="contact-list-name"
                    >
                      {{ contact.first_name }} {{ contact.last_name }}
                    </td>
                    <td
                      class="contact-list-status"
                      style="width:30%"
                    >
                      <div class="td-wrap">
                        <span class="contact-list-label">Status:</span>
                        <contact-status
                          :status="contact.status"
                          :contact-id="contact.id"
                          :on-change="changeContactStatus"
                        />
                      </div>
                    </td>
                    <td
                      v-b-toggle="`contact-${contact.id}`"
                      class="contact-list-visitdate text-center"
                    >
                      <span class="contact-list-label">Visit Date:</span>
                      <b-badge
                        class="inverted-badge"
                        style="width:150px;"
                      >
                        <feather-icon
                          icon="CalendarIcon"
                          class="mr-25"
                        />
                        <span
                          v-if="contact.visit_date"
                          :class="{ 'event-cancelled': contact.is_event_cancelled }"
                        >{{ contact.visit_date | formatDate }}</span>
                        <span v-else>Not Scheduled</span>
                      </b-badge>
                    </td>
                    <td
                      style="width:10%"
                      class="list-subscribe-col"
                    >
                      <contact-subscription
                        :status="contact.subscribed_to_communication"
                        :contact-id="contact.id"
                        :on-change="changeContactSubscription"
                        label="Subscribe"
                      />
                    </td>
                    <td
                      class="text-right"
                      style="width:100px;"
                    >
                      <b-button-group
                        size="md"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="contact-list-deletebutton"
                          variant="outline-danger"
                          :disabled="isSubmitting"
                          @click="showConfirmDeleteModal(contact.id)"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                        <b-button
                          v-b-toggle="`contact-${contact.id}`"
                          class="contact-list-collapsebutton"
                          variant="outline-primary"
                        >
                          <feather-icon icon="ChevronDownIcon" />
                        </b-button>
                      </b-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-collapse
                :id="`contact-${contact.id}`"
                class="contact-details-inner"
              >
                <b-container class="full-width">
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex align-items-center"
                    >
                      <h2 class="contact-details-name">
                        {{ contact.first_name }} {{ contact.last_name }}
                      </h2>
                      <b-button
                        class="contact-details-editbutton"
                        size="sm"
                        variant="primary"
                        :to="{ name: 'edit-contact', params: { contactId: contact.id }}"
                      >
                        Edit
                      </b-button>
                    </b-col>
                    <b-container

                      class="mt-20 full-width"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          sm="6"
                          class="mb-3 mb-sm-0"
                        >
                          <div class="contact-details-phonemail">
                            <a
                              v-if="contact.phone"
                              :href="`tel:${contact.phone}`"
                            >
                              <span>
                                <feather-icon
                                  class="contact-details-icon"
                                  icon="SmartphoneIcon"
                                />
                                <span>{{ contact.phone }}</span>
                              </span>
                            </a>
                            <a :href="`mailto:${contact.email}`">
                              <span>
                                <feather-icon
                                  class="contact-details-icon"
                                  icon="MailIcon"
                                />
                                <span>{{ contact.email }}</span>
                              </span>
                            </a>
                          </div>
                          <div class="contact-details-status">
                            <div class="td-wrap justify-content-start">
                              <span class="contact-list-label">Status:</span>
                              <contact-status
                                :status="contact.status"
                                :contact-id="contact.id"
                                :on-change="changeContactStatus"
                              />
                            </div>
                          </div>
                          <div class="contact-details-subscription">
                            <contact-subscription
                              :status="contact.subscribed_to_communication"
                              :contact-id="contact.id"
                              :on-change="changeContactSubscription"
                              label="Subscribe"
                            />
                          </div>
                        </b-col>
                        <b-col>
                          <h4>Contact details:</h4>
                          <b-card
                            class="small-padding small-margin-bottom"
                            no-body
                          >
                            <b-card-body class="no-padding">
                              <div class="contact-details-list-inner">
                                <b-avatar
                                  variant="light-primary"
                                  square
                                  style="margin-right:15px;"
                                >
                                  <feather-icon icon="MapPinIcon" />
                                </b-avatar>
                                <span>
                                  Location: <strong>{{ contact.location || '-' }}</strong>
                                </span>
                              </div>
                            </b-card-body>
                          </b-card>

                          <b-card
                            class="small-padding small-margin-bottom"
                            no-body
                          >
                            <b-card-body class="no-padding">
                              <div class="contact-details-list-inner">
                                <b-avatar
                                  variant="light-primary"
                                  square
                                  style="margin-right:15px;"
                                >
                                  <feather-icon icon="CalendarIcon" />
                                </b-avatar>
                                <span>
                                  Visit Date: <strong class="d-inline-block">{{ contact.visit_date|formatDate }}</strong>
                                </span>
                              </div>
                            </b-card-body>
                          </b-card>
                          <b-card
                            class="small-padding small-margin-bottom"
                            no-body
                          >
                            <b-card-body class="no-padding">
                              <div class="contact-details-list-inner">
                                <b-avatar
                                  variant="light-primary"
                                  square
                                  style="margin-right:15px;"
                                >
                                  <feather-icon icon="ClockIcon" />
                                </b-avatar>
                                <span>
                                  Visit Time: <strong>{{ contact.visit_time|formatTime }}</strong>
                                </span>
                              </div>
                            </b-card-body>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-container>
                    <b-col />
                  </b-row>
                </b-container>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col v-else>
          <div class="text-center my-5">
            <b-spinner
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="Large Spinner"
              variant="primary"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <template v-if="total > perPage">
      <b-row>
        <b-col
          cols="12"
          class="bottom-pagination-col"
        >
          <b-pagination
            v-model="currentPage"
            :size="screenWidth > 480 ? '' : 'sm'"
            :total-rows="total"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCol,
  BCollapse,
  BContainer,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import AccountService from '@/services/AccountService'
import Ripple from 'vue-ripple-directive'
import ContactSubscription from '@/views/components/contacts/ContactSubscription.vue'
import ContactStatus from '@/views/components/contacts/ContactStatus.vue'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BButtonGroup,
    BContainer,
    BCollapse,
    BCardBody,
    ContactSubscription,
    ContactStatus,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: ['searchQuery'],
  data() {
    return {
      isSubmitting: false,
      currentSort: 'id',
      currentSortDir: 'desc',
      currentPage: 1,
      perPage: 10,
      filter: null,
      filteredContacts: [],
      total: 0,
      screenWidth: screen.width,
    }
  },
  computed: {
    contacts() {
      return store.getters['contacts/contactList']
    },
  },
  watch: {
    filter() {
      // when user is changing the filter, let's jump to page 1
      this.currentPage = 1
      this.updateFilteredContacts()
    },
    contacts() {
      // TODO - react on contacts loaded in a better way than this hack
      if (!this.filter) {
        this.filter = ''
      } else {
        this.filter += ' '
      }
      // this.updateFilteredContacts()
    },
    currentPage() {
      this.updateFilteredContacts()
    },
    currentSort() {
      this.updateFilteredContacts()
    },
    currentSortDir() {
      this.updateFilteredContacts()
    },
  },
  mounted() {
    let debounceTimer
    window.addEventListener('resize', () => {
      clearTimeout(debounceTimer)
      debounceTimer = setTimeout(function () {
        this.screenWidth = screen.width
      }, 500)
    })
  },
  created() {
    if (this.searchQuery) {
      this.filter = this.searchQuery
    }
  },
  methods: {
    showConfirmDeleteModal(contactID) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this item.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteContact(contactID)
          }
        })
    },
    setAndToggleSort(sortField) {
      if (sortField === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = sortField
    },
    changeContactStatus(contactId, status) {
      // update store
      const contacts = this.contacts.map(contact => {
        // eslint-disable-next-line eqeqeq
        if (contact.id == contactId) {
          return {
            ...contact,
            status,
          }
        }
        return contact
      })
      store.dispatch('contacts/setUpdatedContactList', contacts)
      this.updateFilteredContacts()
    },
    changeContactSubscription(contactId, value) {
      // update store
      const contacts = this.contacts.map(contact => {
        if (contact.id == contactId) {
          return {
            ...contact,
            subscribed_to_communication: value,
          }
        }
        return contact
      })
      store.dispatch('contacts/setUpdatedContactList', contacts)
      this.updateFilteredContacts()
    },
    getFilteredContacts(contacts, filter) {
      if (!contacts) {
        return []
      }
      let filtered
      if (filter) {
        const searchTerms = filter.toLowerCase()
          .split(' ')
          .filter(value => value.trim() !== '')

        filtered = contacts.filter(contact => {
          let searchString = (`${contact.first_name} ${contact.last_name} ${contact.email} ${contact.phone}
            ${contact.location} ${contact.visit_date} ${contact.visit_time} ${contact.service_name} `
          )

          if (contact.visit_date) {
            searchString += `${this.$options.filters.formatDate(contact.visit_date)} `
          }

          if (contact.visit_time) {
            searchString += `${this.$options.filters.formatTime(contact.visit_time)} `
          }
          searchString = searchString.toLowerCase()

          let wordsFound = 0
          searchTerms.forEach(word => {
            if (searchString.includes(word)) {
              wordsFound += 1
            }
          })
          return wordsFound === searchTerms.length
        })
      } else {
        filtered = contacts
      }
      return filtered
    },
    updateFilteredContacts() {
      const filtered = this.getFilteredContacts(this.contacts, this.filter)
      const sorted = filtered.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') modifier = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
        return 0
      })
      this.total = filtered.length
      this.filteredContacts = sorted.slice(
        (this.currentPage - 1) * this.perPage,
        (this.currentPage) * this.perPage,
      )
    },
    async deleteContact(contactId) {
      // delete and update component's contact list without calling the backend
      this.isSubmitting = true
      try {
        await AccountService.deleteContact(contactId)
          .then(() => {
            this.isSubmitting = false
            // update store
            // eslint-disable-next-line eqeqeq
            const contacts = this.contacts.filter(contact => contact.id != contactId)
            store.dispatch('contacts/setUpdatedContactList', contacts)
            this.updateFilteredContacts()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                text: 'Item deleted successfully',
                variant: 'success',
              },
            })
          })
      } catch (error) {
        this.isSubmitting = false
      }
    },
    checkIfFilterVisible(currentSort, name) {
      return currentSort.toLowerCase() === name.toLowerCase()
    },
    changeFilterIcon(dir) {
      return dir === 'asc' ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .contact-row {
    border: 1px solid black;
    padding: 2em;
  }

  .contact-details {
    /*display: none*/
  }

  .contact-details-name {
    display: inline-block;
  }

  .contact-details-editbutton {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .filter-container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0.75rem;
    width: 100%;
    max-width: 100%;
  }

  .td-wrap {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }

  .inverted-badge {
    background: transparent;
    border: 1px solid var(--purple);
    padding: 0.75rem;
    color: var(--purple);
  }

  .no-padding {
    padding: 0;
  }

  .contact-details-inner {
    margin-left: -21px;
    background: #fbfbfb;
    width: calc(100% + 42px);
    margin-bottom: -21px;
    padding: 1.5rem 0.75rem;
    margin-top: 21px;
  }

  .no-padding-lr {
    padding-left: 0;
    padding-right: 0;
  }

  .contact-details-phonemail {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }

  .contact-details-phonemail > * {
    margin-right: 25px;
  }

  .contact-details-list-inner {
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .small-padding {
    padding: 0.75rem;
  }

  .small-margin-bottom {
    margin-bottom: 0.75rem;
  }

  .container.full-width {
    max-width: 100%;
  }

  .top-pagination-button {
    text-align: right;
  }

  .pagination ~ .top-pagination-button {
    margin-top: 1.5rem;
  }

  .top-pagination-col ul {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }

  .bottom-pagination-col ul {
    justify-content: center;
  }

  .list-subscribe-col {
    text-align: center;
  }

  .list-subscribe-col label {
    font-size: 1rem;
  }

  .contact-list-card {
    margin-bottom: 1rem;
  }

  .contact-list-table td {
    padding: 0 5px;
  }

  .td-wrap .custom-select {
    max-width: 190px;
  }

  .input-group-lg {
    max-width: 100% !important;
  }
  .event-cancelled {
    text-decoration: line-through;
  }

  .top-row {
    align-items: center;
  }

  .contact-list-name {
    width: 20%;
  }

  .contact-list-visitdate {
    width: 30%;
  }

  .contact-list-label {
    margin-right: 20px;
  }

  .contact-details-status {
    display: none;
    margin-bottom: 1.5rem;
  }

  .contact-details-subscription {
    display: none;
  }

  .contact-details-icon {
    margin-right: 5px;
    margin-top: -4px;
    vertical-align: middle;
  }

  @media (max-width: 1500px) {
    .top-filter-col {
      flex: 0 0 100%;
      margin-bottom: 2rem;
      max-width: 100%;
      text-align: center;
    }

    .top-pagination-col {
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .top-pagination-col .pagination {
      grid-column-start: 2;
      grid-column-end: 3;
      justify-self: center;
    }

    .top-pagination-button {
      grid-column-start: 3;
      justify-self: flex-end;
    }

    .pagination ~ .top-pagination-button {
      margin-top: 0;
    }

    .top-pagination-col ul {
      justify-content: center;
    }
  }

  @media (max-width: 1400px) {
    .list-subscribe-col {
      display: none;
    }

    .contact-list-label {
      margin-right: 10px;
    }

    .contact-details-subscription {
      display: block;
    }
  }

  @media (max-width: 991px) {
    .td-wrap {
      flex-wrap: wrap;
    }

    .contact-list-label {
      display: block;
    }

    .contact-details-phonemail > * {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    .top-pagination-col {
      display: block;
    }

    .pagination ~ .top-pagination-button {
      margin-top: 1.5rem;
    }

    .contact-list-filterbuttons .btn {
      padding: 0.486rem 1rem;
    }

    .contact-list-status {
      display: none;
    }

    .contact-details-status {
      display: block;
    }

    .contact-list-name {
      width: 30%;
    }

    .contact-list-visitdate {
      width: 50%;
    }
  }

  @media (max-width: 640px) {
    .contact-details-inner {
      display: block !important;
      height: auto !important;
      transition: none;
    }
    .contact-list-visitdate, .contact-list-collapsebutton, .contact-details-name {
      display: none;
    }

    .contact-details-editbutton {
      margin-left: 0;
      margin-bottom: 1rem;
    }

    .contact-list-name {
      width: 100%;
    }

    .contact-list-deletebutton {
      border-radius: 0.358rem !important;
    }
  }

  @media (max-width: 575px) {
    .contact-list-filterbuttons .btn {
      padding: 0.486rem 0.486rem;
    }
  }

  @media (max-width: 480px) {
    .contact-list-filterbuttons {
      flex-wrap: wrap;
    }

    .contact-list-filterbuttons .btn {
      margin-left: 0px !important;
      margin-top: -1px;
      width: 100%;
    }

    .contact-list-filterbuttons .btn:first-of-type {
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 0.358rem !important;
    }

    .contact-list-filterbuttons .btn:last-of-type {
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0.358rem !important;
    }
  }
  .dark-layout .contact-details-inner{
    background: #161d31;
  }
</style>
