<template>
  <b-container class="full-width no-padding-lr">
    <b-row>
      <b-col :md="this.show_score ? '2' : '3'">
        <b-card class="text-center">
          <div class="stat-holder justify-content-start justify-content-sm-center">
            <b-avatar
              variant="light-success"
              size="lg"
            />
            <div class="truncate text-left">
              <h2 class="mb-25 font-weight-bolder">
                {{ kFormatter(total_counter) }}
              </h2>
              <span>Total contacts</span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col :md="this.show_score ? '6' : '9'">
        <b-card class="text-center">
          <b-container class="full-width no-padding-lr">
            <b-row>
              <b-col sm="4">
                <div class="stat-holder justify-content-start justify-content-sm-center mb-2 mb-sm-0">
                  <b-avatar
                    variant="light-primary"
                    size="lg"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="visited_counter_icon"
                    />
                  </b-avatar>

                  <div class="truncate text-left">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ kFormatter(visited_counter) }}
                    </h2>
                    <span>Visited</span>
                  </div>
                </div>
              </b-col>
              <b-col sm="4">
                <div class="stat-holder justify-content-start justify-content-sm-center mb-2 mb-sm-0">
                  <b-avatar
                    variant="light-primary"
                    size="lg"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      class="scheduled_counter_icon"
                    />
                  </b-avatar>
                  <div class="truncate text-left">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ kFormatter(scheduled_counter) }}
                    </h2>
                    <span>Scheduled</span>
                  </div>
                </div>
              </b-col>
              <b-col sm="4">
                <div class="stat-holder justify-content-start justify-content-sm-center">
                  <b-avatar
                    variant="light-danger"
                    size="lg"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="did_not_attend_icon"
                    />
                  </b-avatar>
                  <div class="truncate text-left">
                    <h2 class="mb-25 font-weight-bolder">{{ kFormatter(did_not_attend_counter)
                    }}</h2>
                    <span>Did not attend</span>
                  </div>
                </div>
              </b-col>

            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <template v-if="this.show_score">
        <b-col :md="this.show_score ? '4' : '0'">
          <b-card>
            <div id="chart">
              <VueApexCharts
                type="donut"
                height="250"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>
<script>
import {
  BAvatar, BCard, BCardBody, BCardFooter, BCardHeader, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'

export default {
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    BCardFooter,
    BAvatar,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  props: ['show_score'],
  data() {
    return {
      series: [44, 55, 67, 83],
      chartOptions: {
        chart: {
          height: 200,
          type: 'radialBar',
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Visitor Experience Score',
          align: 'left',
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: '"Montserrat", Helvetica, Arial, serif',
            color: '#5e5873',
          },
        },
        legend: {
          show: true,
          floating: false,
          fontSize: '16px',
          position: 'right',
          offsetX: 0,
          offsetY: 60,
          fontFamily: '"Montserrat", Helvetica, Arial, serif',
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter(seriesName, opts) {
            return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`
          },
          itemMargin: {
            vertical: 3,
          },
          style: {
            fontFamily: '"Montserrat", Helvetica, Arial, serif',
          },
        },
        plotOptions: {
          donut: {
            dataLabels: {
              name: {

                fontSize: '18px',
                fontFamily: '"Montserrat", Helvetica, Arial, serif',
              },
              value: {

                fontSize: '14px',
                fontFamily: '"Montserrat", Helvetica, Arial, serif',
              },
              total: {
                show: true,
                label: 'Rating',
                formatter(w) {
                  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                  return 249
                },
              },
            },
          },
        },
        labels: ['Great (9-10)', 'Okay (6-8)', 'Not Good (1-5)', 'No Reply'],
      },
    }
  },
  computed: {
    total_counter() {
      return store.getters['contacts/contactList'] ? store.getters['contacts/contactList'].length : 0
    },
    visited_counter() {
      return store.getters['contacts/contactList']
        ? store.getters['contacts/contactList'].filter(contact => contact.status == 'first_visit').length : 0
    },
    scheduled_counter() {
      return store.getters['contacts/contactList']
        ? store.getters['contacts/contactList'].filter(contact => contact.visit_at).length : 0
    },
    did_not_attend_counter() {
      return store.getters['contacts/contactList']
        ? store.getters['contacts/contactList'].filter(contact => contact.status == 'didnt_attend').length : 0
    },
  },
  created() {
    this.calcStats()
  },
  methods: {
    calcStats() {

    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num)
    },
  },
}
</script>
<style lang="scss" scoped>
  .container.full-width {
    max-width: 100%;
  }

  .no-padding-lr {
    padding-left: 0;
    padding-right: 0;
  }

  .truncate {
    width: 100%;
    margin: 0.75rem 0 0;
  }

  .stat-holder {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .truncate.text-left {
    padding-left: 25px;
    width: auto;
  }

  .b-avatar-lg {
    width: 4.5rem;
    height: 4.5rem;
  }

  .visited_counter_icon,
  .scheduled_counter_icon,
  .did_not_attend_icon {
    width: 2rem;
    height: 2rem;
  }

  .apexcharts-legend.apexcharts-align-center.position-left {
    left: 0 !important;
  }
</style>
